import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../shared/Modal';
import Thumbnail from '../../../shared/Thumbnail';
import Config from '../../../../config';

import wooThumb from './assets/woo.png';
import bigCommerceThumb from './assets/bigcommerce.png';
import etsyThumb from './assets/etsy.png';
import shopifyThumb from './assets/shopify.png';

const SelectPlatform = ({ connectStep, setConnectStep, setConnectProvider }) => {
  const onShopifyStoreConnectClick = () => {
    // https://gooten.atlassian.net/browse/TECH-13769
    const showAppStoreConnectUI =
      Config.get('env') === 'live' &&
      Config.get('isPartner') === true &&
      Config.get('isPartnerAdmin') !== true;

    if (showAppStoreConnectUI) {
      window.location.hash = '#/hub/shopify/connect-store';
    } else {
      setConnectProvider('shopify');
    }
  };

  return (
    <Modal
      className="small"
      title="Select a Platform"
      isOpen={connectStep === 1}
      cancelClick={() => setConnectStep(0)}
    >
      <div className="body-text-2 heavy d-flex flex-column m-4 align-items-center justify-content-center">
        <div
          className="d-flex align-items-center justify-content-start w-100  m-2 cursor-pointer"
          onClick={() => onShopifyStoreConnectClick()}
        >
          <div className="mr-2">
            <Thumbnail size="medium" image={shopifyThumb} />
          </div>
          <div className="ml-1">Shopify</div>
        </div>
        <div
          className="d-flex align-items-center justify-content-start w-100  m-2 cursor-pointer"
          onClick={() => setConnectProvider('etsy')}
        >
          <div className="mr-2">
            <Thumbnail size="medium" image={etsyThumb} />
          </div>
          <div className="ml-1">Etsy</div>
        </div>
        <div
          className="d-flex align-items-center justify-content-start w-100  m-2 cursor-pointer"
          onClick={() => setConnectProvider('woocommerce')}
        >
          <div className="mr-2">
            <Thumbnail size="medium" image={wooThumb} />
          </div>
          <div className="ml-1">WooCommerce</div>
        </div>
        <div
          className="d-flex align-items-center justify-content-start w-100  m-2 cursor-pointer"
          onClick={() => setConnectProvider('bigcommerce')}
        >
          <div className="mr-2">
            <Thumbnail size="medium" image={bigCommerceThumb} />{' '}
          </div>
          <div className="ml-1">BigCommerce</div>
        </div>
      </div>
    </Modal>
  );
};

SelectPlatform.propTypes = {
  connectStep: PropTypes.number.isRequired,
  setConnectStep: PropTypes.func.isRequired,
  setConnectProvider: PropTypes.func.isRequired
};

export default SelectPlatform;
