import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import Config from 'gooten-components/src/config';

const customerRefundStateSelector = state => state.get('customerRefund');

export const customerRefundReasonsSelector = createSelector(
  [customerRefundStateSelector],
  state => {
    return state.get('allReasons');
  }
);

export const customerRefundDataSelector = createSelector([customerRefundStateSelector], state =>
  state.get('customerRefundData')
);

export const orderSummarySelector = createSelector(
  [customerRefundDataSelector],
  customerRefundDataState => customerRefundDataState.get('orderSummary')
);

export const customerRefundHistorySelector = createSelector([customerRefundStateSelector], state =>
  state.get('customerRefundHistory')
);

export const partnerDetailsSelector = createSelector([customerRefundStateSelector], state =>
  state.get('partnerDetails')
);

export const toastTextSelector = createSelector([customerRefundStateSelector], state =>
  state.get('toastText')
);

export const selectedReasonSelector = createSelector([customerRefundStateSelector], state =>
  state.get('selectedReason')
);

export const showLoadingSelector = createSelector([customerRefundStateSelector], state =>
  state.get('loading')
);

export const submitCustomerRefundRequestSelector = createSelector(
  [orderSummarySelector, selectedReasonSelector, partnerDetailsSelector],
  (orderSummary, selectedReason, partnerDetails) => {
    const request = {
      queryParams: {
        recipeId: partnerDetails.get('RecipeId'),
        partnerBillingKey: partnerDetails.get('ApiKey')
      },
      body: {
        OrderSafeId: orderSummary.get('SafeId'),
        UserId: Config.get('adminUserId'),
        UserName: Config.get('adminUserName')
      }
    };

    return request;
  }
);
