import { fromJS } from 'immutable';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import {
  SAVE_CUSTOMER_REFUND_DATA,
  SAVE_CUSTOMER_REFUND_HISTORY,
  SHOW_LOADING,
  SAVE_PARTNER_DETAILS,
  TOAST_TEXT,
  INIT_ALL_REASONS,
  REASON_SELECT,
  REFRESH
} from './CustomerRefundActions';

export const defaultState = fromJS({
  customerRefundData: {},
  customerRefundHistory: [],
  loading: false,
  partnerDetails: {},
  partnerPreferences: {},
  toastText: '',
  allReasons: [],
  selectedReason: 0
});

const customerRefundReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_REFUND_DATA:
      return state.set('customerRefundData', action.payload);

    case SAVE_CUSTOMER_REFUND_HISTORY:
      return state.set('customerRefundHistory', action.payload);

    case SHOW_LOADING:
      return state.set('loading', action.payload);

    case SAVE_PARTNER_DETAILS:
      return state.set('partnerDetails', action.payload);

    case TOAST_TEXT:
      return state.set('toastText', action.payload);

    case INIT_ALL_REASONS:
      return state.set('allReasons', action.payload);

    case REASON_SELECT:
      return state.set('selectedReason', action.payload);

    case REFRESH:
    case RESET:
      return defaultState;

    default:
      return state;
  }
};

export default customerRefundReducer;
