import { fetch, postJson } from 'gooten-components/src/utils/http';
import _fetch from 'isomorphic-fetch';
import Config from 'gooten-components/src/config';

const customerRefundReasons = [
  { id: 1, name: "Received part of my order and someone else's" },
  { id: 2, name: "Received someone else's entire order" },
  { id: 3, name: 'Broken/Damaged' },
  { id: 4, name: 'Wrong product' },
  { id: 5, name: 'Wrong size' },
  { id: 6, name: 'Incorrect cut' },
  { id: 7, name: 'Incomplete Order Item' },
  { id: 8, name: 'Blurry Print' },
  { id: 9, name: 'Printed color off vs submitted image' },
  { id: 10, name: 'Lines through printed image' },
  { id: 11, name: 'Missing print location' },
  { id: 12, name: 'Graphic Crooked, Off-center, Too High / Low' },
  { id: 13, name: 'Ink Spot/Smudge' },
  { id: 14, name: 'Distorted/stretched image' },
  { id: 15, name: 'Ghosting/Residual images' },
  { id: 16, name: 'Peeling image' },
  { id: 17, name: 'Faded image' },
  { id: 18, name: 'Scaled image' },
  { id: 19, name: 'Printed on wrong side' },
  { id: 20, name: 'Wrong color product' },
  { id: 21, name: 'Image orientation' },
  { id: 22, name: 'Printed on the wrong side' },
  { id: 23, name: 'Received incomplete order' },
  { id: 24, name: 'Competitor packaging' },
  { id: 25, name: 'Did not receive - tracking says delivered' },
  { id: 26, name: 'Lost in transit' },
  { id: 27, name: 'No tracking number' },
  { id: 28, name: 'Returned' },
  { id: 29, name: 'Shipping label created - not shipped' },
  { id: 30, name: 'Shipping delay' },
  { id: 31, name: 'Shipping issue' },
  { id: 32, name: 'CS courtesy refund/reprint' },
  { id: 33, name: 'Sample refund' },
  { id: 34, name: 'Out of stock' },
  { id: 35, name: 'OoSLA' },
  { id: 36, name: 'Cancelled' },
  { id: 37, name: 'Pulled from shipping' },
  { id: 38, name: 'Technology Issue' }
];

const getAuthQueryParams = () => ({
  Authorization: 'Bearer ' + Config.get('adminApiToken')
});

class CustomerRefundService {
  // gets customer refund data from local storage, passed in from order details...
  // validate and clear local storage
  getCustomerRefundData(orderId) {
    const data =
      localStorage.getItem('pio.customerRefundData') &&
      JSON.parse(localStorage.getItem('pio.customerRefundData'));
    localStorage.removeItem('pio.customerRefundData');

    return data && data.orderSummary.OrderId.toString() === orderId
      ? {
          orderSummary: data.orderSummary,
          allowMultipleRefunds: data.orderSummary.AllowMultipleCustomerRefunds,
          orderId: data.orderSummary.OrderId,
          customerOrderSubtotal: parseFloat(data.orderSummary.Meta['pb_total_price_net'] || '0'),
          customerOrderTotal: parseFloat(data.orderSummary.Meta['pb_customer_total'] || '0'),
          customerOrderTax: parseFloat(data.orderSummary.Meta['pb_customer_tax'] || '0'),
          customerOrderShipping: parseFloat(
            data.orderSummary.Meta['pb_shipping_price_gross'] || '0'
          )
        }
      : null;
  }

  getOrderCustomerRefundHistory(orderSafeId, partnerDetails) {
    const url = `${Config.get('api')}CustomerRefundHistory?recipeId=${
      partnerDetails.RecipeId
    }&partnerBillingKey=${encodeURIComponent(partnerDetails.ApiKey)}&id=${orderSafeId}`;
    return fetch(url, { method: 'GET' }).then(res => res.CustomerRefunds);
  }

  getPartnerDetails(partnerId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/PartnersApi/GetDetails?partnerId=${partnerId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }

  submitCustomerRefund(request) {
    const url = `${Config.get('api')}customerRefundRequest`;

    return new Promise((resolve, reject) => {
      postJson(url, request.body, { queryParams: request.queryParams })
        .then(res => {
          if (!res.HadError) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAllCustomerRefundReasons() {
    // return async for ability to move to backend in future
    return new Promise((resolve, reject) => {
      resolve(customerRefundReasons);
    });
  }
}

// singleton
export default new CustomerRefundService();
