import React from 'react';
import PropTypes from 'prop-types';
import PanelLayout from '../../layouts/PanelLayout';
import ImageEditor from './atoms/ImageEditor';
import VariantsList from './atoms/VariantsList';
import ProductSection from '../shared/ProductSection';
import Pager from '../shared/Pager';
import Loader from '../shared/Loader';
import PrintGuidelines from './atoms/PrintGuidelines';
import PrintGuidelinesContent from './atoms/PrintGuidelines/PrintGuidelinesContent';
import SideBar from './../shared/SideBar';
import TrackingBar from '../shared/TrackingBar';
import analyticsService from '../../services/analyticsService';
import ImageSelectionModal from '../shared/ImageSelectionModal';
import { validationRules as shopifyRules } from '../ProductPublish/ProviderForms/ShopifyPublishForm';
import { List } from 'immutable';
import ProductDetails from '../ProductSelection/atoms/ProductDetails';
import Embroidery from './atoms/Embroidery';
import EmbroideryGuide from './atoms/Embroidery/EmbroideryGuide';
import Orientation from './atoms/Orientation';

import './ImageUpload.scss';
import Button from '../shared/Button';
import Dialogue from '../shared/Dialogue';
import PageBody from '../shared/PageBody';
import { noPreviewsThresholdNumber } from './ImageUploadSelectors';
import EmbroideryDisclaimer from './atoms/Embroidery/EmbroideryDisclamer';
import KnowledgeBaseInfo from '../shared/KnowledgeBaseInfo';
import Config from '../../config';
import {
  IMAGE_TRANSPARENCY_KB_TEXT,
  IMAGE_TRANSPARENCY_KB_LINK,
  IMAGE_TRANSPARENCY_KB_LINK_FUJI,
  IMAGE_TRANSPARENCY_KB_LINK_SENSARIA
} from '../../constants';
import NeckLabelsCOF from './atoms/NeckLabels';
import imageEditorService from './services/imageEditorService';
import { hashHistory } from 'react-router';
import DesignTemplates from './atoms/DesignTemplates';
import { DESIGN_TEMPLATE_PACKAGE_APPAREL_URL } from '../../constants';

const editorModes = {
  print: 'print',
  product: 'product'
};

class ImageUploadComponent extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,

    selectedProductName: PropTypes.string.isRequired,
    hasProductDetails: PropTypes.bool.isRequired,
    hasProductVariants: PropTypes.bool.isRequired,
    loadProductVariants: PropTypes.func.isRequired,
    loadProductDetails: PropTypes.func.isRequired,

    variants: PropTypes.array.isRequired,
    currentVariantIndex: PropTypes.number,
    currentVariant: PropTypes.object,
    currentSpace: PropTypes.object,
    currentSpaceIndex: PropTypes.number,
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    panelShown: PropTypes.bool,
    allImagesFilled: PropTypes.bool,
    anyImageFilled: PropTypes.bool,
    isWorking: PropTypes.bool,
    spacesLoaded: PropTypes.bool,
    editorMode: PropTypes.string,
    isImagesMissingModalOpen: PropTypes.bool,
    modeChange: PropTypes.func.isRequired,
    totalNumberOfItemsToProcess: PropTypes.number.isRequired,
    remainingNumberOfItemsToProcess: PropTypes.number.isRequired,
    variantSelect: PropTypes.func.isRequired,
    defaultVariantSelect: PropTypes.func.isRequired,
    setReady: PropTypes.func.isRequired,
    templatesInit: PropTypes.func.isRequired,
    imageAdd: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    initImageModal: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    resetImageUpload: PropTypes.func.isRequired,
    hideImagesMissingModal: PropTypes.func.isRequired,
    showImagesMissingModal: PropTypes.func.isRequired,
    skuOverflow: PropTypes.number,
    nextButtonLabel: PropTypes.string,
    backButtonLabel: PropTypes.string,
    prepareImages: PropTypes.func.isRequired,
    sideBarOpened: PropTypes.bool,
    showSideBar: PropTypes.func,
    showPrintGuidelines: PropTypes.bool,
    setCartItemEditMode: PropTypes.func.isRequired,
    isCartItemEditMode: PropTypes.bool,
    isOrientationChanged: PropTypes.bool,
    connectedStores: PropTypes.instanceOf(List).isRequired,
    sidebar: PropTypes.shape({
      isOpen: PropTypes.bool
    }).isRequired,
    closeSideBar: PropTypes.func.isRequired,
    isEmbroidery: PropTypes.bool,
    isApparelProduct: PropTypes.bool,
    editOrderedImage: PropTypes.func,
    isMultiproduct: PropTypes.bool,
    isBulkEdit: PropTypes.bool,
    bulkLoaderProgress: PropTypes.object,
    orientationDisabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.onImageApply = this.onImageApply.bind(this);

    // 'resetImageUpload' has to be called before 'toggleBulkMode' (ImageEditor),
    // because otherwise 'bulkEdit' from state will be reset after setting it,
    // and ImageEditor will not work ONLY for single sku
    this.props.resetImageUpload();
  }

  state = {
    isSkusOverflowModalOpen: false,
    isEmbroideryGuideOpen: false,
    innerDocClick: true
  };

  componentDidMount() {
    //User's mouse is inside the page.
    document.onmouseover = () => this.setState({ innerDocClick: true });

    //User's mouse has left the page.
    document.onmouseleave = () => this.setState({ innerDocClick: false });

    if (!this.props.variants.length) {
      this.props.back();
      return;
    }
    // Cart item, we already have everything prepared for ImageEditor
    // so it's safe to, set to ready, here, to avoid disabled 'Save' button state,
    // on first load
    if (this.props.isCartItemEditMode) {
      this.props.setReady();
    }

    if (!this.props.hasProductDetails || !this.props.hasProductVariants) {
      if (!this.props.hasProductDetails) {
        this.props.loadProductDetails(this.props.selectedProductName);
      }
      if (!this.props.hasProductVariants) {
        this.props.loadProductVariants(this.props.selectedProductName);
      }
      return;
    }
    analyticsService.trackWithConfigSource('Design Page Reached');

    if (this.props.currentVariant) {
      const variant = this.props.currentVariant;
      // find is there any image in any spaces
      let isArtworkApplied = variant.spaces.some(space => space.images.length);
      if (isArtworkApplied) {
        this.removeEventHandlers();
        this.addEventHandlers();
      }
    }

    if (!this.props.spacesLoaded) {
      this.props.templatesInit();
      return;
    }
    // NOTE: If some variants has images but no IL
    // E.g. IL's were ignored b/c templates got changed
    // Dispatch add images action - to generate IL's and small previews
    const imagesToPrepare = [];
    this.props.variants.forEach(v => {
      v.spaces.forEach(s => {
        if ((!s.il || this.props.isOrientationChanged) && s.images && s.images.length) {
          const image = s.images[0];
          const layerId = s.template.layers.find(l => l.type === 'image').id;
          imagesToPrepare.push({
            variantIndex: v.index,
            spaceId: s.id,
            layerId,
            imageUrl: image.imageUrl,
            width: image.width,
            height: image.height
          });
        }
      });
    });

    this.props.prepareImages(imagesToPrepare);

    this.props.defaultVariantSelect(this.props.variants);
    this.props.setReady();
  }

  addEventHandlers() {
    window.addEventListener('beforeunload', this.beforeUnloadListener);
    window.addEventListener('popstate', this.popstateListener);

    const listItems = document.querySelectorAll('ul.nav.navbar-nav > li');

    listItems.forEach(element => {
      const adminElement = document.querySelector('ul.nav.navbar-nav > li.admin');
      if (adminElement && element !== adminElement) {
        element.addEventListener('mousedown', this.mouseDownEventListener);
      }
    });

    const navbarTextTitleElement = document.querySelector(
      'div.admin-main-content > nav.navbar > div.container > span.navbar-text.title'
    );
    if (navbarTextTitleElement)
      navbarTextTitleElement.addEventListener('mousedown', this.mouseDownEventListener);

    const topMenuActionAccountDropDownActions = document.querySelectorAll(
      'div.top-menu-wrapper > div.dropdown.navbar-text.pull-right.top-menu-action-logout ul.dropdown-menu >  li > a[href*="account"]'
    );

    topMenuActionAccountDropDownActions.forEach(element => {
      element.addEventListener('mousedown', this.mouseDownEventListener);
    });

    const topMenuActionSettingsDropDownActions = document.querySelectorAll(
      'div.top-menu-wrapper > div.dropdown.navbar-text.pull-right.top-menu-action-logout ul.dropdown-menu >  li > a[href*="settings"]'
    );

    topMenuActionSettingsDropDownActions.forEach(element => {
      element.addEventListener('mousedown', this.mouseDownEventListener);
    });

    const topMenuActionCart = document.querySelector('a.top-menu-action-cart');
    if (topMenuActionCart)
      topMenuActionCart.addEventListener('mousedown', this.mouseDownEventListener);

    const viewAsBtn = document.querySelector(
      'div.admin-main-content nav.navbar > div.container > a.pull-left.btn.btn-default.view-as-btn'
    );
    if (viewAsBtn) viewAsBtn.addEventListener('mousedown', this.mouseDownEventListener);

    const navBarBrand = document.querySelector('a.navbar-brand');
    if (navBarBrand) navBarBrand.addEventListener('mousedown', this.mouseDownEventListener);
  }

  removeEventHandlers() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
    window.removeEventListener('popstate', this.popstateListener);

    const listItems = document.querySelectorAll('ul.nav.navbar-nav > li');

    listItems.forEach(element => {
      const adminElement = document.querySelector('ul.nav.navbar-nav > li.admin');
      if (adminElement && element !== adminElement) {
        element.removeEventListener('mousedown', this.mouseDownEventListener);
      }
    });
    const navbarTextTitleElement = document.querySelector(
      'div.admin-main-content > nav.navbar > div.container > span.navbar-text.title'
    );
    if (navbarTextTitleElement)
      navbarTextTitleElement.removeEventListener('mousedown', this.mouseDownEventListener);

    const topMenuActionAccountDropDownActions = document.querySelectorAll(
      'div.top-menu-wrapper > div.dropdown.navbar-text.pull-right.top-menu-action-logout ul.dropdown-menu >  li > a[href*="account"]'
    );

    topMenuActionAccountDropDownActions.forEach(element => {
      element.removeEventListener('mousedown', this.mouseDownEventListener);
    });

    const topMenuActionSettingsDropDownActions = document.querySelectorAll(
      'div.top-menu-wrapper > div.dropdown.navbar-text.pull-right.top-menu-action-logout ul.dropdown-menu >  li > a[href*="settings"]'
    );

    topMenuActionSettingsDropDownActions.forEach(element => {
      element.removeEventListener('mousedown', this.mouseDownEventListener);
    });
    const topMenuActionCart = document.querySelector('a.top-menu-action-cart');
    if (topMenuActionCart)
      topMenuActionCart.removeEventListener('mousedown', this.mouseDownEventListener);

    const viewAsBtn = document.querySelector(
      'div.admin-main-content nav.navbar > div.container > a.pull-left.btn.btn-default.view-as-btn'
    );
    if (viewAsBtn) viewAsBtn.removeEventListener('mousedown', this.mouseDownEventListener);

    const navBarBrand = document.querySelector('a.navbar-brand');
    if (navBarBrand) navBarBrand.removeEventListener('mousedown', this.mouseDownEventListener);
  }

  popstateListener = event => {
    if (this.state.innerDocClick) return;
    if (
      window.location.href.indexOf('create-product') < 0 ||
      window.location.href.indexOf('edit-design') < 0 ||
      window.location.href.indexOf('edit-product') < 0
    ) {
      const isHubAllLocation = window.location.href.indexOf('hub/all') > 0;
      const isPlaceOrderLocation = window.location.href.indexOf('/place-order') > 0;
      const isOrderNewLocation = window.location.href.indexOf('/orders-new') > 0;
      const isCustomeOrderHubProductsLocation =
        window.location.href.indexOf('/custom-order-form/hub-products') > 0;
      const isCustomeOrderPastOrdersLocation =
        window.location.href.indexOf('/custom-order-form/past-orders') > 0;
      const isCustomeOrderFormLocation = window.location.href.indexOf('/custom-order-form?') > 0;
      const isCustomeOrderFormCartLocation =
        window.location.href.indexOf('/custom-order-form/create-new/product-selection') > 0;
      const isLinkProductLocation =
        window.location.href.indexOf('/link-product/skus-selection') > 0;
      if (
        isHubAllLocation ||
        isPlaceOrderLocation ||
        isCustomeOrderHubProductsLocation ||
        isCustomeOrderPastOrdersLocation ||
        isCustomeOrderFormLocation ||
        isCustomeOrderFormCartLocation ||
        isLinkProductLocation ||
        isOrderNewLocation
      ) {
        const backResponse = confirm(
          'You pressed Back Button. Your changes will not be saved. Are you sure you want to leave?'
        );
        if (!backResponse) {
          event.preventDefault();
          if (isPlaceOrderLocation) hashHistory.push('/hub/edit-design/image-upload');
          if (isOrderNewLocation) hashHistory.push('/orders-new/edit-design/image-upload');
          if (isHubAllLocation) hashHistory.push('/hub/create-product/image-upload');
          if (isCustomeOrderHubProductsLocation)
            hashHistory.push('/custom-order-form/edit-design/image-upload');
          if (isCustomeOrderPastOrdersLocation)
            hashHistory.push('/custom-order-form/edit-design/image-upload');
          if (isCustomeOrderFormLocation)
            hashHistory.push('/custom-order-form/create-new/image-upload');
          if (isCustomeOrderFormCartLocation)
            hashHistory.push('/custom-order-form/cart/image-upload');
          if (isLinkProductLocation) {
            const indexOf = window.location.href.indexOf('/link-product');
            let url = window.location.href.slice(0, indexOf);
            url = url + '/link-product/product-design/image-upload';
            url = url.split('#')[1];
            hashHistory.push(url);
          }
        }
      }
    }
  };

  mouseDownEventListener(event) {
    if (event.which !== 1) {
      return;
    }
    const alert = confirm('Your changes will not be saved. Are you sure you want to leave?');
    if (!alert) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      let toBeClicked = event.target.closest('a');
      if (toBeClicked) {
        toBeClicked.click();
      } else {
        let toBeClickedDiv = event.target.closest('div[header-user-link]');
        toBeClickedDiv.click();
      }
    }
  }

  beforeUnloadListener = event => {
    event.preventDefault();
    return (event.returnValue = 'Your changes will not be saved. Are you sure you want to leave?');
  };

  componentDidUpdate() {
    if (!this.props.variants.length) {
      this.props.back();
    }

    if (this.props.currentVariant) {
      const variant = this.props.currentVariant;
      // find is there any image in any spaces
      let isArtworkApplied = variant.spaces.some(space => space.images.length);
      if (isArtworkApplied) {
        this.removeEventHandlers();
        this.addEventHandlers();
      }
    }

    if (this.props.currentVariant) {
      this.updatePrintAreaButtons(this.props.currentVariant);
    }
  }

  componentWillUnmount() {
    this.removeEventHandlers();

    // be sure to set edit mode to false, when unmounting ImageUpload component
    // if it was in edit mode
    if (this.props.isCartItemEditMode) {
      this.props.setCartItemEditMode(false);
    }
  }

  onImageApply(images) {
    // Pass user to Print mode if space contains Bleed layer
    if (
      this.props.editorMode !== editorModes.print &&
      this.props.currentSpace &&
      this.props.currentSpace.template.layers.find(l => l.type === 'bleed')
    ) {
      this.props.modeChange(editorModes.print);
    }

    // open left panel after image is uploaded
    if (!this.props.panelShown) {
      this.props.togglePanel();
    }
    // NOTE: How Image Modal works:
    // Bulk - add images for currently selected space into all SKUs
    // Bulk - if the space doesn't exists for some SKUs - skip it
    // Single - add images for all spaces of selected SKU
    const completeImages = [];
    images.forEach(image => {
      const variant = this.props.variants.find(v => v.index === image.variantIndex);
      // spaceIndex is based of spaces with at least 1 image layer
      // some products may have spaces without image layer
      // these should NOT be taken in to account
      const space = variant.spaces.filter(s => s.template.layers.find(l => l.type === 'image'))[
        image.spaceIndex
      ];
      if (space) {
        const layerId = space.template.layers.find(l => l.type === 'image').id;
        if (this.props.isEmbroidery) {
          if (
            space.template.requiredImageSize.width === image.width &&
            space.template.requiredImageSize.height === image.height
          ) {
            completeImages.push({
              variantIndex: image.variantIndex,
              spaceId: space.id,
              layerId,
              imageUrl: image.imageUrl,
              imageId: image.imageId,
              width: image.width,
              height: image.height,
              // flag that image was added via bulk mode - apply image to SKU
              bulk: image.bulk
            });
          }
        } else {
          completeImages.push({
            variantIndex: image.variantIndex,
            spaceId: space.id,
            layerId,
            imageUrl: image.imageUrl,
            imageId: image.imageId,
            width: image.width,
            height: image.height,
            // flag that image was added via bulk mode - apply image to SKU
            bulk: image.bulk
          });
        }
      }
    });
    this.props.imageAdd(completeImages);
    this.updatePrintAreaButtons();
  }

  wrappedContent(content) {
    return (
      <PageBody
        className={`image-upload-container position-relative ${
          this.props.panelShown ? 'normal' : ''
        }`}
        size="full"
      >
        <ImageSelectionModal imageApply={this.onImageApply} />
        <EmbroideryDisclaimer />
        <Dialogue
          isOpen={
            this.state.isSkusOverflowModalOpen && this.props.connectedStores.includes('shopify')
          }
          cancelText="Okay"
          cancelClick={() => {
            this.hideSkusOverflowWarning();
          }}
          title="Just a Reminder..."
        >
          You currently have {this.props.variants.length} SKUs, Shopify only allows a maximum
          of&nbsp;
          {shopifyRules.productVariants.maxNumberOfSkus}. Delete
          {this.props.variants.length - shopifyRules.productVariants.maxNumberOfSkus}&nbsp; SKUs to
          continue publishing.
        </Dialogue>
        <Dialogue
          isOpen={this.props.isImagesMissingModalOpen}
          cancelText="Okay"
          cancelClick={() => {
            this.props.hideImagesMissingModal();
          }}
          confirm={() => {
            this.props.hideImagesMissingModal();
          }}
          title="Something is missing..."
        >
          Please check all of your multi-sided SKUs and upload images to any missing space to
          continue.
        </Dialogue>
        <Dialogue
          isOpen={
            this.props.remainingNumberOfItemsToProcess > 0 &&
            this.props.totalNumberOfItemsToProcess > 1
          }
          title="Processing..."
        >
          Processing{' '}
          {this.props.totalNumberOfItemsToProcess - this.props.remainingNumberOfItemsToProcess} of{' '}
          {this.props.totalNumberOfItemsToProcess} images <br />
          <TrackingBar
            className="mt-3"
            current={
              this.props.totalNumberOfItemsToProcess - this.props.remainingNumberOfItemsToProcess
            }
            total={this.props.totalNumberOfItemsToProcess}
          />
        </Dialogue>
        <EmbroideryGuide
          isOpen={this.state.isEmbroideryGuideOpen}
          onClose={() => this.setState({ isEmbroideryGuideOpen: false })}
        />
        {content}
        <SideBar
          isOpen={this.props.sideBarOpened}
          onClose={() => this.props.showSideBar(false)}
          sameWidthAsPanel
        >
          <PrintGuidelinesContent />
        </SideBar>
        <SideBar isOpen={this.props.sidebar.isOpen} onClose={() => this.props.closeSideBar()}>
          <ProductDetails hidePick onGetStarted={() => this.props.closeSideBar()} />
        </SideBar>
        <Dialogue
          isOpen={
            this.props.isBulkEdit && // single uploads do not show this dialogue
            this.props.variants &&
            this.props.variants.length > noPreviewsThresholdNumber && // there are more than 10 variants
            this.props.bulkLoaderProgress && // there is bulk load counter data
            this.props.bulkLoaderProgress.get('current') !==
              this.props.bulkLoaderProgress.get('total') // load incomplete
          }
          noBackdrop
          title="Processing..."
        >
          Applying&nbsp;
          {this.props.bulkLoaderProgress &&
            `${this.props.bulkLoaderProgress.get('current')} of ${this.props.bulkLoaderProgress.get(
              'total'
            )}`}
          &nbsp; images <br />
          <div className="tracking-bar mt-3 w-100 d-flex justify-content-start">
            <div className="fill-bar" />
          </div>
        </Dialogue>
        <style jsx>
          {`
            .tracking-bar {
              height: 2rem;
              border-radius: 4px;
              overflow: hidden;
              box-shadow: inset 2px 1px 7px #e2e2e2;
            }
            .fill-bar {
              background: #092dc5;
              transition-duration: 0.2s;
              height: 2rem;
              width: ${this.props.bulkLoaderProgress
                ? Math.floor(
                    (this.props.bulkLoaderProgress.get('current') /
                      this.props.bulkLoaderProgress.get('total')) *
                      100
                  )
                : '0'}%;
            }
          `}
        </style>
      </PageBody>
    );
  }

  updatePrintAreaButtons(currentVariant) {
    const variant = currentVariant || this.props.currentVariant;
    // find is there any image in any spaces, and show or hide print area buttons
    this.showPrintAreaButtons = variant.spaces.some(space => space.images.length);
  }

  showSkusOverflowWarning() {
    this.setState({ isSkusOverflowModalOpen: true });
  }

  hideSkusOverflowWarning() {
    this.setState({ isSkusOverflowModalOpen: false });
  }

  editorConfig() {
    const eConfig = this.props.config.editorConfig;
    const recomendedEditorSize = imageEditorService.getRecomendedEditorSize(
      this.props.currentVariant.spaces?.length > 1
    );

    eConfig.width = recomendedEditorSize.width;
    eConfig.height = recomendedEditorSize.height;
    eConfig.maximizedWidth = recomendedEditorSize.width;
    eConfig.maximizedHeight = recomendedEditorSize.height;
    // we still don't support mobile sizes, so left this as it was...
    eConfig.mobileWidth = 300;
    eConfig.mobileHeight = 300;
    return eConfig;
  }

  buttonsContent() {
    let bulkImageUpload = () => {
      analyticsService.trackWithDynamicSource('Image upload', 'Bulk image upload clicked');
      if (this.props.skuOverflow > 0 && this.props.connectedStores.includes('shopify')) {
        this.showSkusOverflowWarning();
        return;
      }
      const space = this.props.currentVariant.template.Spaces[this.props.currentSpaceIndex];
      this.props.initImageModal({
        bulk: true,
        spaceIndex: this.props.currentSpaceIndex,
        spaceName: space.Description || space.Id
      });
    };

    let btnUploadImagesBlock =
      this.props.variants.length === 1 &&
      this.props.config.editDesignMode &&
      this.props.config.editDesignMode.active ? null : (
        <Button
          className={`button-primary large btn-bulk-upload ${
            this.props.anyImageFilled ? 'w-50 ml-1' : 'w-100 mt-3'
          }`}
          onClick={() => bulkImageUpload()}
        >
          Bulk upload artwork
        </Button>
      );

    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          {this.showPrintAreaButtons || this.props.anyImageFilled ? (
            <Button
              className="button-primary large btn-editor-mode mr-1 w-50"
              onClick={() =>
                this.props.modeChange(
                  this.props.editorMode === editorModes.product
                    ? editorModes.print
                    : editorModes.product
                )
              }
            >
              {this.props.editorMode === editorModes.product ? 'View Print Area' : 'View Product'}
            </Button>
          ) : (
            btnUploadImagesBlock
          )}
          {this.showPrintAreaButtons || this.props.anyImageFilled ? btnUploadImagesBlock : null}
        </div>
        <style jsx>
          {`
            min-width: 280px;
            width: ${imageEditorService.getRecomendedEditorSize(
              this.props.currentVariant.spaces?.length > 1
            )?.width}px;
          `}
        </style>
      </>
    );
  }

  render() {
    if (
      !this.props.spacesLoaded ||
      !this.props.currentVariant ||
      !this.props.hasProductDetails ||
      !this.props.hasProductVariants ||
      !this.props.currentSpace
    ) {
      return this.wrappedContent(<Loader />);
    }

    let spaceSelect = index => {
      this.props.variantSelect(
        this.props.currentVariantIndex,
        index,
        this.props.currentVariantIndex,
        this.props.currentSpaceIndex
      );
    };

    const getImageTransparencyKbLink = () => {
      const partnerGroup = Config.get('partnerGroup');
      if (!partnerGroup) return IMAGE_TRANSPARENCY_KB_LINK;

      switch (partnerGroup.get('Id')) {
        case 1:
          return IMAGE_TRANSPARENCY_KB_LINK_FUJI;
        case 2:
          return IMAGE_TRANSPARENCY_KB_LINK_SENSARIA;
        default:
          return IMAGE_TRANSPARENCY_KB_LINK;
      }
    };

    let nextButtonClick = () => {
      if (!this.props.allImagesFilled) {
        this.props.showImagesMissingModal();
      } else if (this.props.config.isOrderPageEdit) {
        // Orders already processed go to a different flow in saga
        this.props.editOrderedImage();
      } else {
        this.props.next();
      }
    };

    let productSectionBlock = (
      <ProductSection
        minPrice={this.props.minPrice}
        maxPrice={this.props.maxPrice}
        skuOverride={
          this.props.variants || this.props.currentVariant.prpSKU || this.props.currentVariant.sku
        }
      />
    );

    let pager = (
      <Pager
        current={this.props.currentSpaceIndex}
        items={this.props.currentVariant.spaces}
        fullButtons
        goTo={index => spaceSelect(index)}
      />
    );

    let imageEditor = (
      <ImageEditor
        key={this.props.currentVariantIndex + this.props.currentSpace.id}
        config={this.editorConfig()}
        space={this.props.currentSpace}
        mode={this.props.editorMode}
        maximized={!this.props.panelShown}
      />
    );
    let content = !this.props.panelShown ? (
      <div className="editor-panel d-flex expanded" key="content">
        <>
          {productSectionBlock}
          <div className="center-panel-holder d-flex flex-column align-items-center">
            {pager}
            {imageEditor}
            {this.buttonsContent()}
          </div>
          <div className="right-panel-holder" />
        </>
        <style jsx>{`
          :global(.product-section-container) {
            flex-grow: 1;
            flex-basis: 0;
            position: initial !important;
            margin-top: 15px;
          }
          .center-panel-holder {
            flex-basis: 0;
            flex-grow: 1;
          }
          .right-panel-holder {
            flex-basis: 0;
            flex-grow: 1;
          }
        `}</style>
      </div>
    ) : (
      <div className="editor-panel d-flex flex-column align-items-center" key="content">
        <div className="d-flex flex-column align-items-center">
          {pager}
          {imageEditor}
          {this.buttonsContent()}
        </div>
      </div>
    );
    return this.wrappedContent(
      <PanelLayout
        collapsed={!this.props.panelShown}
        togglePanel={this.props.togglePanel}
        onBackButtonClick={() => this.props.back(this.props.isMultiproduct)}
        backButtonLabel={this.props.backButtonLabel || 'Previous Step'}
        ignoreBackConfirmation
        onNextButtonClick={() => nextButtonClick()}
        nextButtonLabel={this.props.nextButtonLabel}
        nextButtonDisabled={
          !this.props.anyImageFilled ||
          this.props.isWorking ||
          this.props.remainingNumberOfItemsToProcess > 0
        }
        isOrderPageEdit={this.props.config.isOrderPageEdit}
        noStatusBar={this.props.config.hideStatusBar}
      >
        {content}
        <div key="panel">
          {productSectionBlock}
          <KnowledgeBaseInfo
            kbArticles={[
              {
                visibleText: IMAGE_TRANSPARENCY_KB_TEXT,
                link: getImageTransparencyKbLink()
              }
            ]}
            positionLeft={true}
          />
          {this.props?.config?.showNeckLabelSelection || this.props?.isCartItemEditMode ? (
            <NeckLabelsCOF />
          ) : null}
          {this.props.showPrintGuidelines ? <PrintGuidelines /> : null} 
          {this.props.isEmbroidery ? null : ( this.props.isApparelProduct ? (
           <DesignTemplates onClick={() => window.open(DESIGN_TEMPLATE_PACKAGE_APPAREL_URL || '', '_blank')} />
          ) : null)}
          {this.props.isEmbroidery ? (
            <Embroidery onClick={() => this.setState({ isEmbroideryGuideOpen: true })} />
          ) : null}
          <Orientation disable={this.props.orientationDisabled} />
          <VariantsList showOverflowWarning={() => this.showSkusOverflowWarning()} />
        </div>
      </PanelLayout>
    );
  }
}

export default ImageUploadComponent;
