import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { formatPrice } from 'gooten-components/src/utils/price';
import Select from 'gooten-components/src/components/shared/Select';
import Input from 'gooten-components/src/components/shared/Input';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';
import { COLORS } from 'gooten-components/src/constants';

const CustomerRefundForm = ({
  customerRefundReasons,
  customerRefundData,
  customerRefundHistory,
  submitCustomerRefund,
  showLoading,
  reason,
  reasonSelect
}) => {
  const notRejectedRefunds = customerRefundHistory.filter(x => x.get('Status') !== false);

  const refundTotal =
    customerRefundData.get('customerOrderTotal') -
    notRejectedRefunds.reduce((agr, x) => agr + x.get('Refund'), 0);

  const allowRefund =
    (customerRefundData.get('allowMultipleRefunds') || notRejectedRefunds.size == 0) &&
    refundTotal > 0;

  const [errors, setErrors] = useState({});

  const [amount, setAmount] = useState(refundTotal.toFixed(2));

  useEffect(() => {
    checkErrors();
  }, [reason, amount]);

  const createOption = option => (
    <option key={option.get('id')} value={option.get('id')}>
      {option.get('name')}
    </option>
  );

  const onSelectReason = e => {
    reasonSelect(parseInt(e.target.value));
  };

  const checkErrors = option => {
    let objs = errors;
    // eslint-disable-next-line
    if (reason == 0) {
      objs = { ...objs, reason: 'Please add refund reason' };
    } else {
      delete objs.reason;
    }

    if (amount < 0) {
      objs = { ...objs, amount: 'Please add refund amount' };
    } else if (amount > refundTotal) {
      objs = { ...objs, amount: 'Available to Refund: ' + formatPrice(refundTotal, 'USD {1}') };
    } else {
      delete objs.amount;
    }

    setErrors({ ...objs });
    return Object.keys(objs).length;
  };

  async function handleSubmit() {
    if (!checkErrors()) {
      submitCustomerRefund({
        amount: amount,
        reason: customerRefundReasons.find(r => r.get('id') === reason)?.get('name')
      });
    }
  }

  const errorMessage = type =>
    Object.keys(errors).includes(type) && (
      <React.Fragment>
        <div className="error-field caption-text d-flex align-items-center">
          <Icon icon="alertCircle" small />
          {errors[type]}
        </div>
        <style jsx>{`
          color: ${COLORS.cayennePepper};
          fill: ${COLORS.cayennePepper};
        `}</style>
      </React.Fragment>
    );

  return (
    <>
      <div className="title-2">Refund</div>

      <div className="order-total">
        <div className="title-4">Order subtotal:</div>
        <div className="cost-holder">
          {formatPrice(customerRefundData.get('customerOrderSubtotal'), 'USD {1}')}
        </div>
      </div>
      <hr className="divider" />
      <div className="order-total">
        <div className="title-4">Total Tax:</div>
        <div className="cost-holder">
          {formatPrice(customerRefundData.get('customerOrderTax'), 'USD {1}')}
        </div>
      </div>
      <hr className="divider" />
      <div className="order-total">
        <div className="title-4">Total Shipping:</div>
        <div className="cost-holder">
          {formatPrice(customerRefundData.get('customerOrderShipping'), 'USD {1}')}
        </div>
      </div>
      <hr className="divider bold" />
      <div className="order-total">
        <div className="title-4">Order Total:</div>
        <div className="cost-holder">
          {formatPrice(customerRefundData.get('customerOrderTotal'), 'USD {1}')}
        </div>
      </div>

      {allowRefund ? (
        <>
          <div className="refund-reason">
            <div className="title-3">Reason for the Refund:</div>
            <div className="reasons-holder">
              <Select
                disabled={false}
                autoComplete="false"
                value={reason}
                noLabel
                onChange={onSelectReason}
              >
                {!reason && <option>Select Reason</option>}
                {customerRefundReasons.map(createOption)}
              </Select>
              {errorMessage('reason')}
            </div>
          </div>

          <div className="refund-amount">
            <div className="title-3">Refund Amount:</div>
            <div className="amount-holder">
              <Input
                type="number"
                disabled={false}
                onChange={e => setAmount(e.target.value)}
                onBlur={e => setAmount(e.target.value)}
                noLabel
                value={amount}
              />
              {errorMessage('amount')}
            </div>
          </div>
          <div className="button-holder">
            <Button
              disabled={!!Object.keys(errors).length || showLoading}
              loading={showLoading}
              className="large-only button-primary large ml-2"
              onClick={handleSubmit}
            >
              Refund
            </Button>
            {!!Object.keys(errors).length && (
              <div className="error-field caption-text d-flex align-items-center">
                <Icon icon="alertCircle" small />
                Please fix errors above
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          <p>No refund transactions allowed.</p>
        </div>
      )}

      <hr />
      <style jsx>{`
        margin: 20px;
        width: 80%;
        display: flex;
        flex-direction: column;

        .refund-reason,
        .order-total,
        .refund-amount {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0;
        }

        .order-total {
          margin-top: 0;
        }

        hr.divider {
          width: 50%;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        hr.bold {
          height: 2px;
          border-width: 0;
          color: black;
          background-color: ${COLORS.neutralDark};
        }

        .title-3 {
          width: 30%;
        }

        .title-4 {
          width: 30%;
          margin: 0;
          padding-left: 40px;
        }

        .cost-holder {
          margin: 0;
          padding-left: 40px;
        }

        .refund-reason {
          margin-left: 0;
        }

        .refund-amount {
          margin-left: 0;
          margin-top: 0;
        }

        .error-field {
          color: ${COLORS.cayennePepper};
          fill: ${COLORS.cayennePepper};
          flex-direction: row;
          width: 130%;
          margin-left: 0px;
          margin-bottom: 0px;
        }

        .button-holder {
          width: 140px;
        }
      `}</style>
    </>
  );
};

CustomerRefundForm.propTypes = {
  customerRefundReasons: PropTypes.instanceOf(List).isRequired,
  submitCustomerRefund: PropTypes.func.isRequired,
  customerRefundHistory: PropTypes.object.isRequired,
  customerRefundData: PropTypes.object.isRequired,
  showLoading: PropTypes.bool.isRequired,
  reason: PropTypes.number.isRequired,
  reasonSelect: PropTypes.func.isRequired
};

export default CustomerRefundForm;
