import React from 'react';
import PropTypes from 'prop-types';
import analyticsService from 'gooten-components/src/services/analyticsService';
import Loader from 'gooten-components/src/components/shared/Loader';
import Notifications from 'gooten-components/src/components/shared/Notifications/Notifications';
import StoreConnect from '../shared/StoreConnect';
import Button from 'gooten-components/src/components/shared/Button';
import Config from '../../../config';

import './ShopifyConnect.scss';

// eslint-disable-next-line
const description = (
  <span>
    Please provide the name of your store (you can find it inside your URL as pictured below) and
    click Connect. You’ll be redirected to your Shopify store and prompted to install the Gooten App
    plugin.
  </span>
);

class ShopifyConnect extends React.Component {
  static propTypes = {
    init: PropTypes.func.isRequired,
    state: PropTypes.object,
    location: PropTypes.object,
    connectStore: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    analyticsService._track(
      'Hub - Integrations',
      'Accessed',
      'Shopify',
      '/shopify/connect-store',
      null
    );
    this.state = { notification: null };
  }

  closeNotification() {
    this.setState({ notification: null });
  }

  componentDidMount() {
    if (this.props.location.query.shop && !this.props.state.errorMsg) {
      this.props.connectStore(this.props.location.query.shop);
    }
    if (!this.props.state.initData.shopify) {
      // this.setState({ notification: { text : betaNotification, type: 'success', icon: 'error-icon' } })
      this.props.init('shopify');
    }
  }

  showAppStoreConnectUI() {
    // https://gooten.atlassian.net/browse/TECH-13769
    return (
      Config.get('env') === 'live' &&
      Config.get('isPartner') === true &&
      Config.get('isPartnerAdmin') !== true
    );
  }

  renderShopifyAppStoreConnectUI() {
    // eslint-disable-next-line
    const desc = (
      <span>
        To connect your Shopify store, simply click the button below. You’ll be redirected to the
        Shopify App Store, where you can install the Gooten App directly to your store.
      </span>
    );
    return (
      <div className="store-connect-container center-block">
        <h3 className="text-center store-connect-title">Connect your Shopify store</h3>
        <p className="center-block text-center store-connect-description">{desc}</p>
        <div className="flex-container-row">
          <div
            className="flex-container"
            style={{ marginTop: '2em', justifyContent: 'center', width: '100%' }}
          >
            <div className="flex-container-form flex-inline-container col-md-7 col-sm-10 col-xs-12 store-connect-form"></div>
          </div>
          <Button
            className="button-cta large col-xs-12 center-block store-btn btn-success"
            onClick={() => {
              window.location.href = 'https://apps.shopify.com/gooten';
            }}
          >
            Connect
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const predefinedShop = this.props.state.initData.shopify
      ? this.props.location.query.shop || this.props.state.initData.shopify.storeNameFromSignup
      : null;
    const inputAddon = <div className="input-group-addon">.myshopify.com</div>;
    return (
      <div className="shopify-connect-container">
        <Notifications
          notification={this.state.notification}
          dismiss={() => this.closeNotification()}
          withBackDrop
        />
        {!this.props.state.initData.shopify ? (
          <Loader />
        ) : this.showAppStoreConnectUI() ? (
          this.renderShopifyAppStoreConnectUI()
        ) : (
          <StoreConnect
            predefinedStoreName={predefinedShop}
            storeProvider="Shopify"
            description={description}
            inputAddon={inputAddon}
            inputLabel="Enter Your Shopify Store Address"
            inputPlaceholder="myperfectstore"
            connectStore={storeName =>
              !this.props.state.errorMsg && this.props.connectStore(storeName)
            }
          >
            <div className="flex-container border-block-img-shopify-container">
              <div className="border-block-img" />
            </div>
          </StoreConnect>
        )}
      </div>
    );
  }
}

export default ShopifyConnect;
