import { connect } from 'react-redux';
import CustomerRefund from './CustomerRefund';
import withErrorBoundary from 'gooten-components/src/hocs/withErrorBoundary';
import {
  customerRefundReasonsSelector,
  customerRefundDataSelector,
  customerRefundHistorySelector,
  showLoadingSelector,
  toastTextSelector,
  selectedReasonSelector
} from './CustomerRefundSelectors';
import {
  initCustomerRefund,
  submitCustomerRefund,
  toastText,
  reasonSelect
} from './CustomerRefundActions';

const mapDispatchToProps = {
  initCustomerRefund: payload => initCustomerRefund(payload),
  submitCustomerRefund: payload => submitCustomerRefund(payload),
  clearToast: () => toastText(''),
  reasonSelect: reasonId => reasonSelect(reasonId)
};

const mapStateToProps = (state, ownProps) => {
  return {
    customerRefundReasons: customerRefundReasonsSelector(state),
    customerRefundData: customerRefundDataSelector(state),
    customerRefundHistory: customerRefundHistorySelector(state),
    showLoading: showLoadingSelector(state),
    toastText: toastTextSelector(state),
    reason: selectedReasonSelector(state)
  };
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(CustomerRefund));
